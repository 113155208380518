import { t } from "i18next";
import { openWarningNotification } from "src/components";
import { getToken, setToken } from "src/utils/token";
import { generalError } from ".";
import { history } from ".";
import { removeToken } from "../utils";

export const errorHandling = (e: any) => {
  if (!e) return generalError;
  const msg =
    e.response.data.msg ||
    e.response.data.message ||
    e.response.data.error ||
    null;
  switch (msg) {
    case "jwt_unauthorized":
      removeToken();
      const exceptRedirect = ["/login"];
      exceptRedirect.findIndex(
        (exception: string) => exception === history.location.pathname
      ) === -1 && history.push("/login");
      openWarningNotification(t("messages.pleaseLoginToContinue"));

      return;
    case "coupon_out_of_stock":
      return { success: false, error: "Coupon is currently out of stock." };
    case "role_not_allowed":
      return { success: false, error: "Role is not allowed to access." };
    case "user_pwd_same":
      return { success: false, error: "Same password not allowed." };
    case "referral_record_exist":
      return { success: false, error: "referral_record_exist" };

    default:
      return generalError;
  }
};

export const isResultSuccess = (result: any) => {
  return result && result["data"] && result["data"]["success"];
};

export const refreshNewToken = (result: any) => {
  const token = result["data"]["refreshToken"] || null;
  if (!token) return;
  return setToken(token);
};

export const setHeader = () => {
  let config = {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  };
  return config;
};
