import moment from "moment";

// 0 = normal, 1 = almost expire, 2 = expired
export const checkExpireDate = (expireDate: string) => {
  let status = 0;
  if (
    moment(expireDate).isSameOrAfter(moment()) &&
    moment(expireDate).diff(moment(), "month") < 1
  )
    status = 1;
  else if (moment(expireDate).isBefore(moment())) status = 2;

  return status;
};

export const checkEmpty = (array: string[]) => {
  for (let item of array) {
    if (item.length === 0) {
      return false;
    }
  }
  return true;
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
